define([
    'lodash',
    'react',
    'coreUtils',
    'prop-types',
    'santa-components',
    'wixFreemiumBanner/utils/translationUtils',
    'wixFreemiumBanner/css/wixAdsCss',
    'wixFreemiumBanner/bi/events.json'
], function (_, React, coreUtils, PropTypes, santaComponents, translationsUtils, wixAdsCss, biEvents) {
    'use strict';
    const createReactElement = santaComponents.utils.createReactElement;

    function getAdDesktopProps(isPreview, href, wixAdsId) {
        return {
            className: isPreview ? 'desktop-top preview' : 'desktop-top',
            id: 'top',
            key: 'desktopTop',
            'data-aid': `${wixAdsId}desktopTop`,
            href,
            target: '_blank',
            rel: 'nofollow',
            style: {height: 50, display: 'flex'}
        };
    }

    function getAdMobileProps(isPreview, href, wixAdsId, isHeaderFixedPosition) {
        const classes = ['mobile-top'];

        if (isPreview) {
            classes.push('preview');
        }

        if (isHeaderFixedPosition) {
            classes.push('fixed-ad-mobile');
        }

        const props = {
            className: classes.join(' '),
            id: 'top',
            key: 'mobileTop',
            'data-aid': `${wixAdsId}mobileTop`,
            style: {height: 38, display: 'flex'}
        };

        if (!isPreview) {
            _.assign(props, {
                href,
                'target': '_blank',
                'rel': 'nofollow'
            });
        }
        return props;
    }

    function getAdOneAppProps(href, wixAdsId, isHeaderFixedPosition, reportBI) {
        const mobileProps = getAdMobileProps(false, href, wixAdsId, isHeaderFixedPosition);
        const onOneAppAdClick = () => {
            reportBI(biEvents.ONE_APP_FREE_BANNER_CLICK, {});
        };
        return Object.assign({}, mobileProps, {className: `${mobileProps.className} one-app-banner`, onClick: onOneAppAdClick});
    }

    class Bunner2 extends React.Component {
        componentDidMount() {
            const {reportBI, isWixAdsForOneApp, isMobileView, isPreview} = this.props;
            if (isWixAdsForOneApp && isMobileView && !isPreview) {
                reportBI(biEvents.ONE_APP_FREE_BANNER_SHOWN, {});
            }
        }
        render() {
            const {wixAdsId, isMobileView, isPreview, isWixAdsAllowed, metaSiteId, isHeaderFixedPosition, translations, isWixAdsForOneApp, reportBI} = this.props;

            const adsTranslation = translationsUtils.getWixFreemiumTranslations(translations, isPreview, metaSiteId);
            //TODO: remove when merging sv_newAds (ask BI first)
            adsTranslation.desktopUrl2 = `${adsTranslation.desktopUrl2}&orig_msid=${metaSiteId}`;
            adsTranslation.mobileUrl2 = `${adsTranslation.mobileUrl2}&orig_msid=${metaSiteId}`;

            const wrapperClasses = {
                'wix-ads-2': true,
                visible: isWixAdsAllowed,
                hidden: !isWixAdsAllowed,
                mobile: isMobileView,
                desktop: !isMobileView
            };

            const wrapperProps = {
                ref: 'wrapper',
                id: wixAdsId,
                className: coreUtils.classNames(wrapperClasses)
            };

            const wrapperChildren = [
                santaComponents.utils.styleNodeUtils.generateStyleNode('wixAds-style', wixAdsCss)
            ];

            if (isWixAdsForOneApp && isMobileView && !isPreview) {
                wrapperChildren.push(createReactElement('a', getAdOneAppProps(adsTranslation.oneAppUrl, wixAdsId, isHeaderFixedPosition, reportBI), adsTranslation.oneAppBanner));
            } else {
                wrapperChildren.push(isMobileView ?
                    createReactElement(isPreview ? 'div' : 'a', getAdMobileProps(isPreview, adsTranslation.mobileUrl2, wixAdsId, isHeaderFixedPosition), adsTranslation.mobileBanner2) :
                    createReactElement('a', getAdDesktopProps(isPreview, adsTranslation.desktopUrl2, wixAdsId),
                        createReactElement('span', {className: 'contents'}, adsTranslation.desktopBanner2Text, createReactElement('span', {'className': 'button'}, adsTranslation.desktopBanner2Button))
                    )
                );
            }

            return createReactElement('div', wrapperProps, wrapperChildren);
        }
    }
    Bunner2.displayName = 'WixFreemiumBanner2';
    Bunner2.propTypes = {
        wixAdsId: PropTypes.string,
        isMobileView: PropTypes.bool,
        isPreview: PropTypes.bool,
        isWixAdsAllowed: PropTypes.bool,
        language: PropTypes.string,
        metaSiteId: PropTypes.string,
        isHeaderFixedPosition: PropTypes.bool,
        translations: PropTypes.object,
        isWixAdsForOneApp: PropTypes.bool,
        reportBI: PropTypes.func
    };
    return Bunner2;
});
